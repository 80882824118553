import React from 'react';
import { Link } from '@amzn/awsui-components-react';

export const ActiveTrainingLink = ({
    labUrl,
    record,
    labId = '',
    title = '',
}) => (
    <p>
        <Link external href={labUrl} onFollow={record}>
            {title || labId}
        </Link>
    </p>
);

import { getLabIdFromLabUrl } from '../utils/helpers';
import { createLabRedirectUrl } from '../utils/urls';

export const transformLabsWithDetailsToDisplay = (
    contentData,
    titleRegex,
    langLocale
) => {
    return ({ labId, blueprintId: arn, statlerUrl: labUrl }) => ({
        labId,
        title: contentData[arn]?.title.replace(titleRegex, ''),
        ...createLabRedirectUrl({
            labUrl,
            contentId: arn,
            langLocale,
        }),
    });
};

export const transformLabsToDisplay = (
    loadedLabs,
    contentData,
    titleRegex,
    langLocale
) => {
    return labUrl => {
        const labId = getLabIdFromLabUrl(labUrl);
        const position = loadedLabs.findIndex(
            activeTraining =>
                activeTraining.labUrl === labUrl ||
                activeTraining.labId === labId
        );
        const { arn } = position > -1 ? loadedLabs[position] : {};
        const title = contentData[arn]?.title.replace(titleRegex, '');
        return {
            ...createLabRedirectUrl({
                labUrl,
                contentId: arn,
                langLocale,
            }),
            title,
            labId,
            arn,
            position,
        };
    };
};

export const convertFulillmentErrorToModal = ({
    getFulfillmentError = {},
    createFulfillmentError = {},
    loadedLabs,
    contentData,
    titleRegex,
    langLocale,
}) => {
    const labsWithDetailsTransformerForModal = transformLabsWithDetailsToDisplay(
        contentData,
        titleRegex,
        langLocale
    );
    const legacyActiveLabsTransformerForModal = transformLabsToDisplay(
        loadedLabs,
        contentData,
        titleRegex,
        langLocale
    );
    if (getFulfillmentError.activeLabsWithDetails) {
        return getFulfillmentError.activeLabsWithDetails.map(
            labsWithDetailsTransformerForModal
        );
    } else if (getFulfillmentError.activeLabs) {
        return getFulfillmentError.activeLabs.map(
            legacyActiveLabsTransformerForModal
        );
    } else if (createFulfillmentError.activeLabsWithDetails) {
        return createFulfillmentError.activeLabsWithDetails.map(
            labsWithDetailsTransformerForModal
        );
    } else if (createFulfillmentError.activeLabs) {
        return createFulfillmentError.activeLabs.map(
            legacyActiveLabsTransformerForModal
        );
    }

    return [];
};
